@import "~styles/theme/index";

.#{$wf__ns}header-menu {
  &.ant-menu {
    padding-top: 2px;
    color: $white;
  }

  &__icon {
    margin-left: 20px;
  }

  .ant-menu-submenu-title {
    padding: 0;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.ant-menu-item.header-menu-item.ant-menu-item-only-child {
  a {
    color: $white;
  }

  &:hover a {
    color: $primary;
  }
}

.notification-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  &__info {
    width: 20px;
    height: 20px;
  }
}
