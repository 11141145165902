@import "~styles/theme/index";

button.prov-switch-box__switch.convert-button,
span.prov-switch-box__switch.convert-button.ant-tooltip-disabled-compatible-wrapper {
  margin: 0 2px 0 5px;
}

.ant-row.ant-form-item.view-coordinate {
  margin: 0;

  .ant-form-item-label {
    width: 50%;

    > label:after {
      margin: 0 2px 0 0;
    }
  }

  .ant-form-item-control {
    padding-left: 6px;
  }
}

