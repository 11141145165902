@import "~styles/theme/index";

.#{$wf__ns}notification {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: $blue4;
  border-radius: 10px;

  &_wrap {
    display: flex;
    font-size: 16px;

    .first-element {
      margin-bottom: 5px;
    }

    &__labels, &__dates {
      display: flex;
      flex-direction: column;
    }

    &__dates {
      color: $white;
      padding-left: 20px;
    }
  }

  &__header-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    >.multi-remover-btn {
      width: 155px;
    }
  }

  .cell {
    &--capitalize {
      text-transform: capitalize;
    }

    &--pre-wrap {
      white-space: pre-wrap;
    }
  }

  .view-icon {
    font-size: 24px;
  }
}
