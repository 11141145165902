@import "~styles/theme/index";

.#{$wf__ns}drag-and-drop-uploader {
  $self: &;
  padding: 1em;
  color: $gray2;

  &__accepted-types {
    margin-top: 1em;
  }

  .ant-upload.ant-upload-drag {
    background: $blue4;
    border-color: $gray2;

    .anticon {
      font-size: 3em;
      color: $gray2;
    }

    #{$self}__text {
      color: $gray2;
      font-size: 15px;

      &_primary {
        color: $primary;
        margin-left: 5px;
      }
    }
  }

  .ant-list-empty-text {
    display: none;
  }

  &__list-item {
    color: $white;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;

    &.ant-list-item.ant-list-item-no-flex {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: none;
    }

    .doc-icon {
      margin-right: 15px;
    }

    .delete-icon {
      margin-left: 30px;
      
      svg {
        width: 18px;
        height: 20px;
      }
    }
  }
}
