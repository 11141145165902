@import "~styles/theme/index";

.#{$wf__ns}direct-message-manager {
  .ant-tabs-bar.ant-tabs-top-bar {
    background-color: $select-background;
    border-radius: 4px;
    user-select: none;
  }

  &__area-form {
    width: 25%;
  }

  >.ant-modal-content {
    max-height: 85vh;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 0;

      .legend-addition-elements {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        margin-left: auto;

        .#{$wf__ns}switch-box__switch {
          margin: unset;
        }
      }
    }

    .ant-table-body {
      .controls {
        justify-content: flex-end;
      }

      .view-icon {
        font-size: 24px;
      }
    }
  }
}
