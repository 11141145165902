@import "~styles/theme/index";

.sector-setup__modal {
  .ant-form-item {
    align-items: flex-start;
  }

  .ant-form-item-label > label {
    color: $gray2;
  }

  .radio {
    &-group {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    &-button {
      display: block;
      height: 30px;
      line-height: 30px;
      color: $gray2;
    }
  }

  .primary-btn {
    &_default {
      display: none;
    }

    &_primary {
      width: 150px;
      margin-right: 85px;
    }
  }

  .ant-modal-content .ant-modal-body .ant-form-item {
    align-items: flex-start;
  }
}
