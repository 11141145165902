@import "~styles/theme/index";

.#{$wf__ns}table-container {
  width: 100%;
  height: max-content;
  overflow: auto;
  background-color: $blue4;
  border-radius: 4px;

  &__header {
    padding: 16px;
    background-color: $blue5;
    font-size: 17px;
    font-weight: 500;
    color: $gray1;
  }

  &__content {
    padding: 28px;
  }
}
