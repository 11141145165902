@import "~styles/theme/index";

.#{$wf__ns}financial-tab__modal {
  .hideable-text {
    display: none;
  }

    .ant-modal-content {
        .ant-modal-body {
            padding: 30px 64px 0 0;

            .ant-form-item:last-of-type {
              align-items: flex-start;
            }
        }

        .ant-modal-footer {
            flex-direction: row;
            padding-bottom: 30px;

            .ant-btn {
                width: 150px;
                margin-right: 120px;
                margin-top: 15px;

                &.primary-btn_default {
                    display: none;
                }
            }
        }
    }
}
