@import "~styles/theme/index";

.#{$wf__ns}engineering-cable-modal {
  .hideable-text {
    display: none;
  }

    .ant-modal-content {
        .ant-modal-body {
            padding: 30px 64px 0 0;
        }

        .ant-modal-footer {
          justify-content: flex-start;
          margin-right: 77px;

            .ant-btn {
                width: 150px;
                margin-left: 122px;
                margin-top: 15px;

                &.primary-btn_default {
                    display: none;
                }
            }
        }
    }
}
