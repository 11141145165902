@import "~styles/theme/index";

.#{$wf__ns}exports-browse {
  &__handler {
      margin-top: 15px;

      .ant-form {
        &-item {
          padding: 0 15px 0 0;
        }

        .ant-row .ant-select-selector {
            width: 185px;
          }
      }
  }

  &__modal {
    .ant-modal-content {
      .ant-modal-body {
        padding-left: 0;
        padding-right: 25px;

        .aligned-form-item {
          align-items: baseline;
        }
      }

      .ant-modal-footer {
        justify-content: flex-start;
        margin-right: 38px;
        margin-left: 178px;
      }
    }
  }
}
