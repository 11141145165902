@import "~styles/theme/index";

.#{$wf__ns}admin-project-category-modal {
  .ant-modal {
    &-body {
      .ant-row.ant-form-item {
        justify-content: center;
      }
    }

    &-footer {
      margin-left: 175px;

      .ant-btn {
        width: 150px;
        margin-right: 47px;
      }
    }
  }
}
