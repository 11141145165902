@import "~styles/theme/index";

$borderRadius: 5px;

.#{$wf__ns}additional-filters {
  display: flex;
  justify-content: space-between;

  &__button-group {
    margin-right: 15px;

    @include dtx {
      display: flex;
      flex-direction: column;
      margin-right: 0px;
    }

    .#{$wf__ns}primary-button {
      $self: &;
      width: auto;

      &.not-active {
        > .ant-btn {
          color: $gray2;
          border-color: $gray3;
        }
      }

      &__wrap.ant-btn {
        border-radius: 0;
      }

      &:first-child {
        & > .ant-btn {
          border: {
            bottom-left-radius: $borderRadius;
            top-left-radius: $borderRadius;
          }
        }
      }

      &:last-child {
        & > .ant-btn {
          border: {
            bottom-right-radius: $borderRadius;
            top-right-radius: $borderRadius;
          }
        }
      }

      @include dtx {
        &__wrap.ant-btn {
          border-radius: $borderRadius;
        }

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
