@import "~styles/theme/index";

.#{$wf__ns}preview-img-modal {
  .ant-modal-content {
    background-color: transparent;

    .ant-modal-body {
      padding: 0;

      img {
        height: 86vh;
        max-width: 90vw;
      }
    }

    .ant-modal-close,
    .ant-modal-footer {
      display: none;
    }
  }
}
