@import "~styles/theme/index";

$ant-modal-header-height: 60px;

.#{$wf__ns}add-or-edit-modal {
  .rule-modal-tooltip-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: calc(#{$ant-modal-header-height} + 15px);
    left: 15px;
  }
}
