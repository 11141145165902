@import "~styles/theme/index";

.#{$wf__ns}site-audit {
  &__header-buttons {
    display: flex;

    > :first-child {
      margin-right: 1em;
    }
  }
}

.#{$wf__ns}additional-filters {
  @include dtx {
    display: flex;
    flex-direction: column;
  }

  &__button-group {
    @include dtx {
      display: flex;
      flex-direction: column;
    }
  }

  > div:last-child {
    .ant-btn {
      @include dtx {
        margin-top: 30px;
      }
    }
  }
}
