@import "~styles/theme/index";

.#{$wf__ns}switch-box {
  color: $gray2;

  & > &__switch {
    margin-right: 15px;
  }

}
