@import "~styles/theme/index";

.#{$wf__ns}permitting-jurisdiction-audit {
  .ant-collapse-content {
    padding: 30px;
  }

  &__tab {
    overflow-y: auto;
    max-height: 61vh;

    &.categories-table-open {
      max-height: 41vh;
    }

    &_with-actions {
      max-height: 57vh;

      &.categories-table-open {
        max-height: 36vh;
      }
    }
  }

  &__section {
    margin-bottom: 25px;

    &__form {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .left {
        grid-column: 1 / 2;
      }

      .right {
        grid-column: 2 / 3;
      }

      .ant-form-item {
        align-items: flex-start;
        height: auto;
      }
    }

    .ant-divider.ant-divider-horizontal {
      border-color: $blue12;
    }
  }

  &__actions {
    margin-top: 30px;

    > *:not(:last-of-type) {
      margin-right: 15px;
    }
  }
}
