@import "~styles/theme/index";

.#{$wf__ns}permitting-jurisdiction-details {
    .ant-tabs-nav {
        padding-top: 10px;
    }

    .prov-permitting-handle__btns {
        margin-top: 24px;

        .action-wrap {
            display: flex;
            justify-content: space-between;
            width: 332px;
        }
    }

  .ant-collapse-content {
    padding: 30px;
  }

  .ant-form-item,
  .ant-input-number {
    align-items: center;
    height: 40px;
  }

  .ant-input-number {
    border-radius: 4px;
  }

  .actions {
    margin-top: 30px;

    > *:not(:last-of-type) {
      margin-right: 15px;
    }
  }
}
