@import "~styles/theme/index";

.#{$wf__ns}add-exist-pace-task {
  &__task-identifier {
    > .ant-col {
      &:first-child {
        max-width: 50%;
        flex: 0 0 50%;
      }

      &:last-child {
        max-width: 100%;
        flex: 0 0 100%;
        justify-content: center;
        text-align: center;
        display: flex;
        margin: 10px 0;
      }
    }
  }
}

