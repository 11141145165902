@import "~styles/theme/index";

$font-size: 15px;
$row-height: calc(#{$font-size} + 5px);

.#{$wf__ns}area-direct-message-viewer {
  .ant-modal-content .ant-modal-body {
    padding: 0;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: $row-height $row-height 1fr;
    grid-row-gap: 15px;
    padding: 40px 20px;

    .item,
    .message {
      color: $font-color;
      font-size: $font-size;
      line-height: 1;

      &__label {
        font-weight: bold;
        margin-right: 10px;
      }

      &__value {
        &.completed {
          color: $gray2;
        }

        &.not-completed {
          color: $turquoise;
        }
      }
    }

    .message {
      max-height: 335px;
      padding: 10px;
      border: 2px solid $input-border;
      border-radius: 5px;
      line-height: unset;
      overflow: auto;
    }
  }
}
