@import "~styles/theme/index";

.#{$wf__ns}permitting-browse {
  &__modal {
    .ant-modal-content {
      .ant-modal-body {
        padding-left: 0;

        .ant-form-item {
          &.aligned-form-item {
            .ant-form-item {
              margin: 0;
            }
          }
        }
      }

      .ant-modal-footer {
        justify-content: flex-start;
        margin-left: 170px;
      }
    }
  }
}
