@import "~styles/theme/index";

.#{$wf__ns}admin-county-modal {
  .ant-modal {
    &-body {
      .ant-row.ant-form-item {
        justify-content: center;
      }
    }

    &-footer {
      margin-left: -80px;

      .ant-btn {
        width: 150px;

        &.primary-btn_default {
          display: none;
        }
      }
    }
  }
}
