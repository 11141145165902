@import "~styles/theme/index";

.status {
  &_green {
    color: $turquoise !important;
  }

  &_gray {
    color: $gray2 !important;
  }

  &_orange {
    color: $orange !important;
  }

  &_blue {
    color: $primary !important;
  }

  &_yellow {
    color: $yellow !important;
  }

  .circle {
    margin-right: 10px;
  }
}
