@import "~styles/theme/index";

.#{$wf__ns}accounting-summary {
  display: flex;

  &.tabs-wrap {
    overflow-y: auto;
    max-height: 61vh;

    &.categories-table-open {
      max-height: 41vh;
    }

    &_with-actions {
      max-height: 57vh;

      &.categories-table-open {
        max-height: 37vh;
      }
    }
  }

  @include dtx {
    flex-direction: column;
  }

  .summary-table {
    min-width: 30vw;

    .ant-table-body {
      max-height: $full-res;

      td {
        padding: 10px;
      }
    }

    .ant-form-item {
      margin: 0;
      padding: 0;
    }

    &__row {
      height: 50px;
    }
  }

  &__information {
    min-width: 600px;
    margin-right: 30px;

    @include dtx {
      margin-right: 0;
    }

    .ant-collapse-content {
      padding: 30px 0 30px 20px;
    }

    .ant-collapse-item.ant-collapse-item-active.ant-collapse-no-arrow .ant-collapse-header {
      background-color: $blue5;
      padding: 16px 0 16px 30px;
    }

    .ant-collapse-header {
      font-weight: 500;
      font-size: 17px;
      line-height: 28px;
    }

    .project,
    .notes {
      margin-bottom: 24px;

      .#{$wf__ns}view-element {
        margin-bottom: 30px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &__label {
          width: 160px;
        }
      }
    }

    .notes {
      .#{$wf__ns}view-element {
        margin-bottom: 10px;

        &__element {
          word-break: break-all;
        }
      }
    }
  }
}
