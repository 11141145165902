@import "~styles/theme/index";

.#{$wf__ns}steps {
  .ant-steps {
    padding: 0 15px;
    margin-bottom: 16px;
    color: $gray2;

    &-item {
      &-process, &-wait, &-finish {
        > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
          color: $gray2;
        }
      }
    }

  }

  &__content {
    display: flex;
    justify-content: space-between;

    &__box {
      width: 100%;

      &__item {
        display: none;

        &.active {
          display: inherit;
        }
      }
    }

    &__arrow {
      &-prev, &-next {
        display: flex;
        align-items: center;
        color: $gray2;
        font-size: 16px;
        padding: 0 10px;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
          color: $gray3;
        }

        &:not(.disabled):hover {
          background-color: rgba(255, 255, 255, .2);
        }
      }
    }
  }
}
