@import "~styles/theme/index";

.#{$wf__ns}sof_tool-browse {
  &__items {
    display: flex;
    align-items: center;

    &_col {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .ant-table-body td {
    padding: 10px 16px;
    line-height: 19px;
  }

  &__modal {
    .ant-modal-content {
      .ant-modal-body {
        padding-left: 0;
        padding-right: 25px;

        .ant-form-item.aligned-form-item {
          align-items: baseline;
        }
      }

      .ant-modal-footer {
        justify-content: flex-start;
        margin-left: 240px;
      }
    }
  }
}
