@import "~styles/theme/index";

.#{$wf__ns}-import-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: $full-res;
  min-height: 470px;
}
