@import "~styles/theme/index";

.#{$wf__ns}unsaved-data-modal {
  .ant-modal-content {

    .ant-modal-body {
      display: flex;
      justify-content: center;
    }

    .ant-modal-footer {
      .ant-btn-primary {
        margin-left: 0;
      }

      .ant-btn.primary-btn_danger-outline {
        margin-left: 25px;
      }
    }
  }

  &__content {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 28px;
    color: $white;
  }
}
