@import "~styles/theme/index";

.#{$wf__ns}leasing-equipment__associated-projects-modal {
  .ant-modal {
    &-content .ant-modal-body {
      display: flex;
      padding: 20px 10px;
      flex-direction: column;
      align-items: flex-end;

      .empty-btn {
        margin-bottom: 15px;
      }

      .ant-row.ant-form-item {
        justify-content: center;
      }
    }

    &-footer {
      margin-left: 100px;

      .ant-btn.primary-btn_primary {
        display: none;
      }

      .ant-btn.primary-btn_default {
        width: 170px;
      }
    }
  }

}
