@import "~styles/theme/index";

.#{$wf__ns}project-category-modal {
  .ant-modal {
    &-footer {
      margin-left: 185px;

      .ant-btn {
        width: 150px;
        margin-right: 38px;
      }
    }
  }
}
