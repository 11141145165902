@import "~styles/theme/index";

.switch-wrapper {
  &.ant-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  &__text {
    margin-right: 15px;

    &-primary {
      color: $primary;
    }

    &-dangerous {
      color: $danger;
    }
  }

  .switch-box-dangerous {
    background: $red-transp2;

    &.ant-switch::after {
      background: $danger;
    }
  }
}
