@import "~styles/theme/index";

.#{$wf__ns}filters-set-modal {
  .ant-divider.ant-divider-horizontal {
    border-color: $blue12;
  }

  .ant-modal-content > .ant-modal-footer {
    justify-content: flex-start;
    margin-right: 20px;

    .ant-btn {
      width: 250px;
      margin-right: 30px;
    }
  }
}
