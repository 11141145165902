@import "~styles/theme/index";

.#{$wf__ns}admin-site-pictures {
  height: $admin-site-pictures-height;
  width: $full-res;
  background: $blue4;
  border-radius: 10px;
  padding: $main-padding-size;

  &__handler {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;

    .ant-select {
      max-width: 400px;

      div.ant-select-selector {
        height: 40px;
        display: flex;
        align-items: center;
      }
    }

    .label {
      display: flex;
      margin-bottom: 20px;

      &__image {
        margin-right: 14px;
        padding: 6px;
        color: $white;
        background: $primary;
        border-radius: 90px;
      }

      &__text {
        display: flex;
        align-items: center;
        max-height: 30px;
        color: $gray2;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
      }
    }
  }

  .ant-empty {
    height: $site-pictures-carousel-height;
  }
}
