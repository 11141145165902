@import "~styles/theme/index";

.#{$wf__ns}create-user-modal {
  &__row {
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
  }

  .ant-select-multiple {
    .ant-select-selector {
      min-height: 64px;

      .ant-select-selection-item {
        background-color: rgba(24, 144, 255, 0.2);
        border-color: $primary;
        color: $primary;

        &-remove svg {
          color: $primary;
        }
      }
    }
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
  }

  .ant-modal-footer {
    margin-left: 80px;
  }
}
