@import "~styles/theme/index";

.#{$wf__ns}site-audit-browse {
  .#{$wf__ns}browse-page__search-box {
    width: 65%;
  }

  &__schedule-form {
    margin-top: 2em;

    .ant-row.ant-form-item {
      padding-left: 0;
    }
  }

  &__modal {
    .ant-modal-content {
      .ant-modal-body {
        padding-left: 0;
        padding-right: 25px;

        .ant-form-item.aligned-form-item {
          align-items: baseline;
        }
      }

      .ant-modal-footer {
        justify-content: flex-start;
        margin-right: 38px;
        margin-left: 244px;
      }
    }
  }
}
