@import "~styles/theme/index";

.#{$wf__ns}change-password-modal {
  &-notification {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    color: $danger;
  }

  .ant-modal-footer {
    margin: 10px 104px 0 233px;
  }
}
