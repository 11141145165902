@import "~styles/theme/index";

.#{$wf__ns}rad-table {
  &_white-column {
    color: $white;
  }

  &__modal {
    .ant-modal-footer {
      margin-right: 7px;
    }
  }

  .controls {
    display: flex;

    .ant-btn {
      border: none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        background: transparent;
      }
    }
  }
}
