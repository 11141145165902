@import "~styles/theme/index";

.#{$wf__ns}site-information-disabling-modal {
  .ant-modal {
    &-footer {
      margin-left: 83px;

      .ant-btn {
        width: 150px;
        margin-right: 24px;
      }
    }
  }
}
