@import "~styles/theme/index";

.#{$wf__ns}pace-tasks {
  width: $full-res;
  height: $full-res;
  padding: 24px 32px;
  background-color: $blue4;
  border-radius: 10px;
  color: $gray2;

  &__info-row {
    margin-bottom: 2em;
  }

  &__table-row.ant-row {
    display: block;
  }
}
