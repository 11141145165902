@import "~styles/theme/index";
// TODO: remove after fixing excessive wrapping all component in ClientLayout (for Max)
// override shit-styles
main.ant-layout-content.prov-layout__content > main.ant-layout-content.prov-layout__content:not(.auth-page) {
  height: calc(100% - #{$breadcrumb-height});
  color: $gray2;
}

.#{$wf__ns} {
  $self: &;
  // content
  &empty-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 166px);
    background-image: url("~assets/images/web-of-lines.png");
    background-position: center;

    &__img-box {
      align-self: center;
    }

    &__logo {
      width: 365px;
    }
  }
  // general
  &dashboard-panel {
    #{$self}custom-table {
      &__grid {
        overflow-x: unset;
      }
    }

    #{$self}table-container__content {
      padding: 1em;
    }

    .ant-table {
      box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;

      &-cell {
        user-select: none;

        .view-icon {
          font-size: 24px;
        }
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 25px;
    }

    &__controls {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        #{$self}switch-box {
          margin-top: 15px;

          &__switch {
            margin: 0;
          }
        }
      }
    }

    &__search {
      width: max-content;
    }
  }
}
