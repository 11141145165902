@import "~styles/theme/index";

.#{$wf__ns}browse-page {
  width: $full-res;
  height: $full-res;
  padding: 24px 32px;
  background-color: $blue4;
  border-radius: 10px;
  color: $gray2;

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__wrap {
    margin-top: 30px;

    .title {
      margin-bottom: 15px;
      color: $secondary;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    .list {
      display: flex;
      justify-content: space-between;
      width: 300px;
      margin-top: 15px;

      &__item {
        display: flex;
        align-items: center;

        .circle {
          margin-right: 15px;
        }

        .text {
          margin-right: 15px;
          font-weight: 500;
          font-size: 13px;
          line-height: $full-res;
        }
      }
    }
  }

  &__filter {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 290px;
    justify-content: space-between;

    &-modal {
      .ant-modal-content > .ant-modal-body {
        padding-left: 52px;
        padding-right: 50px;

        .ant-form-item {
          align-items: center;

          &-label > label {
            height: auto;
          }

          &-control-input-content {
            .ant-row {
              .ant-col-12 {
                .ant-form-item {
                  padding: 0;
                }

                &:last-child {
                  .ant-form-item {
                    padding-left: 13px;
                  }
                }
              }
            }
          }
        }
      }

      .ant-modal-footer {
        margin-right: 64px;

        .primary-btn_primary {
          margin-right: 33px;
        }
      }

      .ant-radio-group {
        margin-left: 23px;
      }

      .ant-btn {
        width: 150px;
      }
    }


    .switch-wrap {
      display: flex;
      justify-content: space-between;
      margin: 15px 0 10px;

      @include landscape {
        flex-direction: column-reverse;
      }

      &__btn {
        width: 50px;
        height: 22px;
      }

      &__info {
        max-width: 200px;
        margin-left: 20px;
        font-size: 14px;

        @include landscape {
          margin-left: 0px;
          margin-bottom: 20px;
        }
      }
    }

    .configure-btn {
      width: 200px;
      margin-bottom: 15px;
    }
  }
}
