@import "~styles/theme/index";

$input-height: 40px;

.#{$wf__ns}auth-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $full-res;
  height: $full-res;
  background-color: $blue6;

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    background-color: $blue3;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 7px;

    .header {
      padding: 21px 32px;
      border-radius: 7px 7px 0 0;
      background-color: $blue5;
      color: $gray1;
    }

    .wrap {
      height: $full-res;
      padding: 32px 32px $input-height;

      .ant-form-item {
        &:last-child {
          margin: 0;
        }

        &-has-error {
          .ant-input-affix-wrapper-focused {
            border-color: $danger;
          }
      }
    }

      &__password.ant-input-password {
        display: flex;
        align-items: center;
        height: $input-height;
        background-color: $blue3;
        border: 1px solid $gray3;

        input {
          height: $full-res;
        }

        input,
        svg {
          background-color: $blue3;
          color: $secondary;
        }
      }

      &__button {
        width: $full-res;
        margin-top: 10px;
      }

      &__input.ant-input {
        height: $input-height;
        background-color: $blue3;
        color: $secondary;
        border: 1px solid $gray3;
        border-radius: 4px;
      }

      &__link {
        display: block;
        margin-top: 20px;
        color: $primary;
        font-size: 13px;
      }
    }
  }
}
