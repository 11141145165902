@import "~styles/theme/index";

.#{$wf__ns}admin-driver-management {
  width: $full-res;
  height: $full-res;
  padding: 24px 32px;
  background-color: $blue4;
  border-radius: 10px;
  color: $gray2;

  &__search-box {
    margin-bottom: 33px;
  }

  &__add-btn {
    margin-top: 37px;
    min-width: 160px;
  }
}
