@import "~styles/theme/index";

.#{$wf__ns}range-picker {
  justify-content: space-between;

  > .ant-col-11 {
    flex: 0 0 calc(50% - 5px);
    max-width: calc(50% - 5px);

    > .ant-form-item {
      padding: 0;
    }

    &:first-child {
      margin-right: 10px;
    }
  }

}
