@import "~styles/theme/index";

.#{$wf__ns}equipment-browse {
  .ant-table-pagination.ant-pagination {
    margin-top: 68px;
  }

  .ant-table-tbody > tr > td {
    padding: 17px 16px;
  }

  &__modal {
    .ant-modal-content {
      .ant-modal-body {
        padding: 30px 50px 15px;
      }

      .ant-modal-footer {
        justify-content: flex-start;
        margin-left: 205px;
        padding-bottom: 30px;
      }
    }
  }
}
