@import "~styles/theme/index";

.#{$wf__ns}financial-export-modal {
    .ant-modal-content {
        .ant-modal-body {
            padding: 30px 32px 0 32px;

            .border-box {
              padding-bottom: 30px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            }
        }

        .ant-modal-footer {
            padding-bottom: 30px;
            margin: 30px 0 0 24px;
            justify-content: flex-end;

            .ant-btn {
                width: 100px;

                &:first-of-type {
                  margin-left: 33px;
                }
            }
        }
    }

    &__text {
        display: inline-block;
        max-width: 365px;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        color: $white;
        margin-bottom: 30px;
    }

    .copy-btn > .prov-primary-button__wrap.ant-btn {
        width: 332px;
    }
}
