@import "~styles/theme/index";

$max-row: 6;

.#{$wf__ns}virtual-table {
  .ant-table-thead .ant-table-cell {
    background-color: $blue5;
    color: $gray1;
    border: none;
    line-height: 22px;

    > div {
      width: 100%;
    }
  }

  &__body {
    color: $gray2;

    .vt-cell {
      display: flex;
      align-items: center;
      padding: 16px;
      font-size: 13px;
      line-height: 1;
      overflow-wrap: anywhere;

      &--odd-row {
        background-color: $blue4;
      }

      &--even-row {
        background-color: $blue3;
      }

      .list {
        display: flex;
        flex-direction: column;

        &__item:not(:last-of-type) {
          margin-bottom: 5px;
        }
      }

      @for $i from 1 through $max-row {
        > .ellipsis-#{$i} {
          display: -webkit-box;
          -webkit-line-clamp: #{$i};
          -webkit-box-orient: vertical;
          overflow : hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
