@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}-Medium.eot');
    src: url('#{$file-path}-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$file-path}-Medium.woff') format('woff'),
    url('#{$file-path}-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: $style;
  }
  
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
    url('#{$file-path}.woff') format('woff'),
    url('#{$file-path}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
  
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}-Black.eot');
    src: url('#{$file-path}-Black.eot?#iefix') format('embedded-opentype'),
    url('#{$file-path}-Black.woff') format('woff'),
    url('#{$file-path}-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: $style;
  }
  
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}-Bold.eot');
    src: url('#{$file-path}-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$file-path}-Bold.woff') format('woff'),
    url('#{$file-path}-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: $style;
  }
}

@include font-face('Montserrat', '~assets/fonts/montserrat/Montserrat');
