@import "~styles/theme/index";

.#{$wf__ns}admin-project-category {
  width: $full-res;
  background: $blue4;
  border-radius: 10px;
  padding: $main-padding-size;

  &__add-button {
    min-width: 230px;
    margin-top: 30px;
  }

  td.ant-table-selection-column {
    padding-right: 105px;
  }

  .controls {
    &__btn {
      width: 20px;
      height: 20px;
      padding: 0;
      border: none;
      cursor: pointer;

      &:first-child {
        margin-right: 25px;
      }

      &:hover,
      &:active,
      &:focus {
        background: none;
      }

      img {
        width: $full-res;
      }
    }
  }

  .prov-primary-button__icon {
    margin-right: 10px;
  }
}
