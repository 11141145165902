@import "~styles/theme/index";

.#{$wf__ns}equipment-select {
  max-width: 250px;

  &__item, &__item.ant-select {
    margin-bottom: 5px;
    width: 100%;

    &--quantity {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;

      > .ant-select {
        width: 190px;;
      }

      > .ant-input-number {
        width: 50px;
      }
    }
  }

  &__add-other-equipment {
    width: 100%;
  }
}
