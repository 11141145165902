@import "~styles/theme/index";

.#{$wf__ns}layout {
  width: $full-res;
  height: 100vh;
  padding-top: 64px;

  &__header.ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: $full-res;
    background: $blue3;
    top: 0;
    z-index: 5;
    line-height: normal;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.5);
    padding: 0 25px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: $full-res;
  }

  &__content {
    background: $dark-blue;
    height: $full-res;

    &.auth-page {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue6;
    }

    .wrapper {
      padding: $layout-wrapper-padding-height;
    }
  }

  &__trigger {
    color: $gray2 !important;
  }

  div.ant-layout-sider-children {
    background: $blue3;

    .ant-menu-inline-collapsed {
      width: 72px;
    }
  }

  &__trigger {
    padding: 20px 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__sider-logo {
    width: $full-res;
  }

  .logo_open {
    display: flex;
    justify-content: flex-end;
    padding-right: 23px;
  }

  .logo_close {
    margin-left: 32px;
  }
}
