@import "~styles/theme/index";

.#{$wf__ns}equipment-type-modal {
  .ant-modal-content {
    .ant-modal-body {
      .ant-row.ant-form-item {
        justify-content: center;
      }
    }

    .ant-modal-footer {
      margin-right: 10px;
      margin-left: 176px;
      justify-content: flex-start;

      .ant-btn {
        width: 150px;
        margin-right: 38px;
      }
    }
  }
}
