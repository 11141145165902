@import "~styles/theme/index";

.#{$wf__ns}permitting-summary {
  &__content {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 35px;

    @include dtx {
      grid-template-columns: 1fr;

      .prov-table-container {
        width: initial;
        margin: 24px 30px 0 0;
      }
    }

    &.tabs-wrap {
      overflow-y: auto;
      max-height: 61vh;

      &.categories-table-open {
        max-height: 41vh;
      }

      &_with-actions {
        max-height: 57vh;

        &.categories-table-open {
          max-height: 36vh;
        }
      }
    }

    > .left {
      > *:not(:last-of-type) {
        margin-bottom: 20px;
      }

      .information {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        .left {
          grid-column: 1 / 2;
        }

        .right {
          grid-column: 2 / 3;
        }
      }

      .action-button {
        margin-top: 30px;
      }
    }

    .#{$wf__ns}table-container {
      &__content {
        .summary-table {
          .ant-table-body {
             max-height: 100vh;

            td {
              padding: 10px;
            }
          }
        }
      }
    }
  }

  &__actions {
    > *:not(:last-of-type) {
      margin-right: 15px;
    }
  }
}
