@import "~styles/theme/index";

.#{$wf__ns}jurisdiction-configurable-panel-header {
  position: relative;

  &__actions {
    position: absolute;
    top: 0;
    right: -60px;

    &.no-hoverable {
      visibility: hidden;
    }

    .action {
      opacity: 0;

      &:hover,
      &.active {
        opacity: 1;
      }
    }
  }

  &__panel-actions {
    box-shadow: 0 0 15px 0 rgba(#000, .5);
    .ant-dropdown-menu {
      background-color: $blue4;

      &-item {
        &:hover {
          background-color: $blue11;
        }
      }
    }
  }
}

