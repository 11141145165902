@import "~styles/theme/index";

.#{$wf__ns}confirmation-popup {
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 10px;
  }

  &__question {
    font-size: 16px;
    font-weight: 500;
  }

  &__footer {
    margin-top: 14px;

    &-btns {
      display: flex;
      justify-content: space-between;
      width: 155px;

      .prov-primary-button {
        min-width: 70px;

        &__wrap {
          height: 30px;
        }
      }
    }
  }
}
