// #RESOLUTION
$full-res: 100%;

// #NAMESPACE
$wf__ns: prov- !default;
$wf__anim: $wf__ns + anim__;

// #MAIN COLORS
$primary: #1890ff;
$hover-primary: #40a9ff;
$secondary: rgba(255, 255, 255, 0.65);
$danger: #f64d4f;

// #COMMON COLORS
$white: #ffffff;
$black: #000000;
$blue1: #005ae1;
$blue2: #15528b;
$blue3: #0e284c;
$blue4: #0b1f3b;
$blue5: #153d74;
$blue6: #0a1f3d;
$blue7: #40a9ff;
$blue8: #104780;
$blue9: #14335e;
$blue10: #006dd2;
$blue11: rgba(24, 144, 255, 0.35);
$blue12: rgba(22, 64, 122, 0.9);
$select-background: #133463;
$no-data: #a8acb2;
$dark-blue: #071223;
$red1: #eb5757;
$red2: #c74244;
$red-transp: rgba(246, 77, 79, 0.1);
$red-transp2: rgba(246, 77, 79, 0.2);
$error-red-transp: rgba(246, 77, 79, 0.2);
$orange: #f2994a;
$orange-transp: rgba(242, 153, 74, 0.2);
$yellow: #f2c94c;
$yellow-transp: rgba(242, 201, 76, 0.2);
$turquoise: #03ffd2;
$gray1: rgba(255, 255, 255, 0.85);
$gray2: rgba(255, 255, 255, 0.45);
$gray3: #324867;
$gray4: #304158;
$font-color: rgba(255, 255, 255, 0.65);
$input-border: rgba(255, 255, 255, 0.15);

// #FONTS
$primary-font: 'Montserrat', sans-serif;

$font-size: 13px;

$layout-header-height: 64px;
$breadcrumb-height: 54px;
$layout-wrapper-padding-height: 24px;
$main-padding-size: 2em;
$main-button-height: 40px;

// site-pictures vars
$admin-site-pictures-height: calc(
  100vh - $layout-header-height - $breadcrumb-height -
    $layout-wrapper-padding-height * 2
);
$admin-site-pictures-heandler-height: 105px;
$site-pictures-carousel-height: calc(
  $admin-site-pictures-height - $main-padding-size * 2 -
    $admin-site-pictures-heandler-height - $main-button-height
);
