$dt2x: 1920px;   //desktop xlarge
$dtx:  1599px;   //desktop large
$dt:   1399px;   //desktop
$ltx:  1259px;   //laptop large
$lt:   1074px;   //laptop
$tbx:  1194px;    //tablet landscape
$tb:   834px;    //tablet


@mixin dt2x {
    @media (min-width: #{$dt2x}) {
        @content;
    }
}

@mixin min-dtx {
  @media (min-width: #{$dtx}) {
    @content;
  }
}

@mixin dtx {
    @media (max-width: #{$dtx}) {
        @content;
    }
}

@mixin dt {
    @media (max-width: #{$dt}) {
        @content;
    }
}

@mixin ltx {
    @media (max-width: #{$ltx}) {
        @content;
    }
}

@mixin lt {
    @media (max-width: #{$lt}) {
        @content;
    }
}

@mixin tbx {
    @media (max-width: #{$tbx}) {
        @content;
    }
}

@mixin tb {
    @media (max-width: #{$tb}) {
        @content;
    }
}

@mixin landscape {
    @media (max-width: #{$tbx})
    and (orientation: landscape)
     {
        @content;
    }
}
