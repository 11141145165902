@import "~styles/theme/index";

.#{$wf__ns}not-found-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(50%);
    font-weight: 500;
    color: $gray2;

    &__title {
        font-size: 30px;
        line-height: 37px;
        margin-top: 20px;
    }

    &__subtitle {
        font-size: 10px;
        line-height: 12px;
    }
}
