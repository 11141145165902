@import "~styles/theme/index";

.#{$wf__ns}search {
    display: flex;
    align-items: center;
    position: relative;

  &__search-icon,
  &__clear-icon {
    position: absolute;
    z-index: 2;
    left: 15px;
    font-size: 17px;
    color: $gray2;
  }

  &__clear-icon {
    left: unset;
    right: 15px;
    font-size: 12px;
    cursor: pointer;
    transition: color .3s ease-out;

    &:hover {
      color: $blue2;
    }
  }

  &__input.ant-input {
    padding-left: 47px;
    max-width: 220px;
    height: 40px;
    border: 1px solid $gray3;
    box-sizing: border-box;
    border-radius: 4px;
    background: $blue4;
    color: $gray2;

    &.allowClear {
      padding: 0 42px 0 47px;
    }

    &:focus,
    &:hover {
      border-color: $gray3;
    }

    @include dtx {
      max-width: 275px;
    }
  }
}
