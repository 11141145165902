@import "~styles/theme/index";

.#{$wf__ns}permitting-permitting-section {
  $root: &;

  .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    #{$root}__add-button {
      margin-left: auto;
      margin-bottom: 25px;
    }
  }
}
