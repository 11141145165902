@import "~styles/theme/index";

.side {
  &.ant-menu {
    height: calc(100vh - 120px);
    overflow-x: hidden;

    .menu-item-selected {
      color: $primary;

      & > a {
        color: $primary;
      }
    }
  }

    &__submenu-item.ant-menu-item {
        margin-left: 30px;

        & > a:hover {
            color: $primary;
        }
    }

    .ant-menu-submenu-inline >
    .ant-menu-submenu-title .ant-menu-submenu-arrow {
        right: 26px;
    }

    &__icon-box {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;

        .side__icon {
            margin-right: 0;
            margin-left: 3px;

            svg {
                width: 19px;
                height: 19px;
                margin-right: 20px;
            }
        }
    }
}
