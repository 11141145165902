@import "~styles/theme/index";

.#{$wf__ns}site-create {
  .#{$wf__ns}site-information {
    .tabs-wrap_with-actions .ant-collapse .ant-collapse-item {
      .ant-collapse-content {
        height: calc(100vh - 290px);
      }
    }

  }
}
