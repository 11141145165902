@import "~styles/theme/index";

.#{$wf__ns}scoping-equipment {
  .tabs-wrap {
    overflow-y: auto;
    max-height: 61vh;

    &.categories-table-open {
      max-height: 41vh;
    }

    &_with-actions {
      max-height: 57vh;

      &.categories-table-open {
        max-height: 35vh;
      }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-content {
      height: max-content;
      margin-bottom: 0;
    }
  }

  &__information {
    margin-bottom: 2em;

    &.#{$wf__ns}other-equipment,
    &.#{$wf__ns}equipment-totals {
      .ant-collapse-content {
        > .ant-collapse-content-box {
          padding: $main-padding-size;

          .#{$wf__ns}primary-button {
            margin-top: 2em;
          }
        }
      }
    }

    .ant-collapse-content {
      > .ant-collapse-content-box {
        padding: 1em 0;
      }
    }

    .#{$wf__ns}sector-equipment {
      .ant-collapse-content {
        > .ant-collapse-content-box {
          padding: $main-padding-size;
        }
      }
    }

    .action-buttons {
      &-container {
        margin-bottom: 2em;

        > .action-buttons {
          display: flex;

          > :not(:last-child) {
            margin-right: 2em;
          }
        }
      }
    }
  }
}
