@import "~styles/theme/index";

.#{$wf__ns}default-tower-agent-modal {
  .hideable-text {
    display: none;
  }

  .ant-modal {
    &-body {
      .ant-row.ant-form-item {
        justify-content: center;
      }
    }

    &-footer {
      margin-left: 150px;

      .ant-btn {
        width: 150px;
        margin-right: 25px;
      }
    }
  }
}
