@import "~styles/theme/index";

.#{$wf__ns}user-management {
  &__handler {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__filters {
    .ant-select-multiple .ant-select-selector {
      min-height: 64px;

      .ant-select-selection-item {
        background-color: rgba(24, 144, 255, 0.2);
        border-color: $primary;
        color: $primary;

        &-remove svg {
          color: $primary;
        }
      }
    }

    .ant-modal-footer {
      margin-left: 170px;
    }
  }

  &__table-wrapper {
    padding: 35px;
    background-color: $blue4;
    border-radius: 10px;
  }
}
