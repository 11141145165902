@import "~styles/theme/index";

.ant-radio-group.mode-handler {
  display: flex;

  .mode-handler.ant-radio-button-wrapper,
  .mode-handler__button.ant-radio-button-wrapper {
    height: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    background: inherit;
    border: 1.2px solid $gray2;

    span {
      color: $gray2;
    }

    &-checked {
      border: 1.2px solid $primary;

      span {
      color: $primary;
    }}
  }
}
