@import "~styles/theme/index";

.#{$wf__ns}admin-dropdown-values {
  width: $full-res;
  background: $blue4;
  border-radius: 10px;
  padding: $main-padding-size;

  &__add-button {
    min-width: 130px;
    margin-top: 30px;
  }

  &__table-handler {
    margin-bottom: 40px;

    .label {
      display: flex;
      margin-bottom: 20px;

      &__image {
        margin-right: 14px;
        padding: 6px;
        color: $white;
        background: $primary;
        border-radius: 90px;
      }

      &__text {
        display: flex;
        align-items: center;
        max-height: 30px;
        color: $gray2;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
      }
    }
  }

  .column-text {
    color: $white;
  }

  .controls {
    &__btn {
      width: 20px;
      height: 20px;
      padding: 0;
      border: none;
      cursor: pointer;

      &:first-child {
        margin-right: 25px;
      }

      &:hover,
      &:active,
      &:focus {
        background: none;
      }

      img {
        width: $full-res;
      }
    }
  }

  .prov-primary-button__icon {
    margin-right: 10px;
  }
}
