@import "~styles/theme/index";

.#{$wf__ns}leasing-details {
    .prov-leasing-handle__btns {
        margin-top: 24px;

        .action-wrap {
            display: flex;
            justify-content: space-between;
            width: 332px;
        }
    }
}

