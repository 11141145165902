@import "~styles/theme/index";

.#{$wf__ns}scoping-tower-note.ant-card {
  background: transparent;
  border-color: $blue5;
  border-width: 3px;
  max-width: 640px;
  min-width: 350px;

  .ant-card-head {
    border-color: $blue5;
    border-width: 3px;

    .edit-btn {
      color: $gray2;

      &:hover {
        color: $gray1;
      }
    }

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $gray1;
      height: 100%;
    }
  }

  .ant-card-body {
    padding: 16px 12px;

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .final {
      display: inline-block;
      margin-top: 24px;
    }
  }
}

.modal {
  .ant-form-item {
    padding: 0;
  }


  .ant-modal-content .ant-modal-body {
    padding: 40px 16px 15px;

    .ant-space-item {
      .ant-row.ant-form-item {
        margin: 0;
      }
    }

    .anticon.anticon-minus-circle {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .ant-btn {
      opacity: .5;

      &:hover {
        opacity: 1;
        background: initial ;
      }
    }
  }
}
