@import "~styles/theme/index";

.#{$wf__ns}equipment-sectors {
  & > &__version {
    margin-bottom: 1em;

    & > .ant-col-12 {
      display: flex;
      justify-content: flex-end;
      gap: 1em;
    }
  }

  & > .ant-collapse:not(:last-child) {
    margin-bottom: 2em;

  }
  .ant-select {
    min-width: 100px
  }
}
