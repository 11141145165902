@import "~styles/theme/index";

.#{$wf__ns}breadcrumb-menu__wrapper {
    width: $full-res;
    background: $blue4;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 16px 0 16px 24px;
    height: $breadcrumb-height;
    display: flex;
    align-items: center;
    z-index: 1;

    span.ant-breadcrumb-separator {
        color: $gray2;
    }

    .ant-breadcrumb .#{$wf__ns}breadcrumb-menu__link {
        color: $gray2;
    }

    .ant-breadcrumb > span:last-child  {
        a, span {
            color: $gray1;
        }
    }
}
