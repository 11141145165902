@import "~styles/theme/index";

.#{$wf__ns}add-edit-direct-message-modal {
  .receiver-wrapper {
    display: flex;
    align-items: center;
    padding: 0 1em;

    .ant-row.ant-form-item {
      flex: 1 0;
    }

    >.anticon.anticon-info-circle {
      margin-bottom: 15px;
      font-size: 24px;
      color: white;
    }
  }
}
