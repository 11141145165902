@import "~styles/theme/index";

.#{$wf__ns}sof-tool-table {
  background: $blue4;
  margin-bottom: 25px;
  padding: 25px 30px;
  border-radius: 4px;

  &__selector.ant-select.ant-select-single:not(.ant-select-customize-input) {
    width: 200px;

    > .ant-select-selector {
      height: 40px;
      border-radius: 4px;

      > .ant-select-selection-item {
        line-height: 38px;
      }
    }
  }
}
