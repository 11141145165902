@import "~styles/theme/index";

.#{$wf__ns}equipment-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 30px;

      .ant-row.ant-form-item {
        justify-content: center;

        &:last-of-type {
          align-items: flex-start;
        }

        .ant-input-number {
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-modal-footer {
      margin-right: 10px !important;
      margin-left: 176px !important;
    }
  }
}
