@import "~styles/theme/index";

.#{$wf__ns}multiple-add-modal {
  &__modal-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .title {
      margin-right: 10px;
    }

    .subtitle {
      font-size: 15px;
      color: $gray2;
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      .ant-row.ant-form-item {
        width: 330px;
        margin: 0 15px 0 0;
        padding: 0;

        .ant-input-number {
          height: 40px;
        }
      }
    }

    .ant-modal-body {
      padding: 30px 50px 10px;
    }

    .dropdown-value {
      display: inline-flex;
      width: 100%;
      margin-bottom: 20px;

      .controls {
        width: auto;
        padding-top: 10px;

        &__btn {
          height: 20px;
          padding: 0;
          border: none;
          cursor: pointer;

          &:first-child {
            margin-right: 15px;
          }

          &:hover,
          &:active,
          &:focus {
            background: none;
          }

          &.remove-field {
            color: $gray2;
          }

          .icon svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .ant-modal-footer {
      justify-content: flex-end;
      margin-left: 50px;

      .ant-btn {
        margin-left: 0;

        &.primary-btn_default {
          margin-left: 30px;
        }
      }
    }
  }
 }
