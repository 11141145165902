@import "~styles/theme/index";

.#{$wf__ns}sof-tool-action-buttons {
  display: flex;
  justify-content: space-between;

  &__general-actions {
    .#{$wf__ns}primary-button:not(:last-of-type),
    .send-button {
      margin-right: 30px;
      min-width: 150px;

      > button {
        width: 100%;
      }
    }
  }
}
