@import "~styles/theme/index";

.#{$wf__ns}add-equipment-modal {
  .ant-modal {
    &-body {
      .ant-row.ant-form-item {
        justify-content: center;
      }
    }

    &-footer {
      justify-content: center;

      .primary-btn {
        &_default {
          display: none;
        }

        &_primary {
          width: 150px;
        }
      }
    }
  }
}
