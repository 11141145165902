@import "~styles/theme/index";

.#{$wf__ns}no-jurisdiction {
  &__info {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: $blue3;
    box-shadow: 0 0 15px 0 rgba(#000, .5);
    color: $white;
    font-weight: 500;
  }
}
