@import "~styles/theme/index";

.#{$wf__ns}financial-drower {
  &.ant-drawer-right {
    .ant-drawer-content-wrapper {
      height: calc(100vh - 64px);

      .ant-drawer-content {
        margin-top: 64px;
        background-color: $blue3;
        overflow-y: auto;

        .ant-drawer-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 14px 25px 24px 25px;

          .fee-list {
            font-weight: 500;
            font-size: 13px;
            line-height: 22px;
            color: $white;

            .closing-cross {
              display: flex;
              justify-content: flex-end;
            }

            .fee-item {
              display: flex;
              padding: 16px 0;
              border-bottom: 1px solid rgba(255, 255, 255, 0.15);

              .close-icon {
                margin-left: 30px;
              }
            }
          }

          .btn-wrapper {
            display: flex;

            .export-btn {
              padding-top: 15px;
              width: 170px;
            }
          }
        }
      }
    }
  }
}
