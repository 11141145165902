@import "~styles/theme/index";

.#{$wf__ns}admin-dropdown-values-modal {
  .ant-modal-content {
    .ant-modal-body {
      .ant-row.ant-form-item {
        justify-content: center;
      }
    }

    .ant-modal-footer {
      justify-content: flex-start;
      margin-right: 20px;
      margin-left: 175px;

      .ant-btn {
        width: 150px;
        margin-right: 28px;
      }
    }
  }
}
