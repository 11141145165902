@import "~styles/theme/index";

.#{$wf__ns}workflow-zoning-section {
  margin-bottom: 25px;

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    .ant-form-item {
      align-items: center;
      height: 40px;
      margin: 0;
      padding: 0;
    }
  }

  .ant-collapse-content {
    padding: 30px;
  }
}
