@import "~styles/theme/index";

.#{$wf__ns}site-audit-tab {
  .tabs-wrap {
    overflow-y: auto;
    max-height: 61vh;

    &.categories-table-open {
      max-height: 63vh;
    }

    &_with-actions {
      max-height: 57vh;

      &.categories-table-open {
        max-height: 57vh;
      }
    }
  }
}
