.site-browse__modal {
  .ant-modal-footer {
    margin-right: 0;
    margin-left: 100px;

    .ant-btn {
      max-width: 140px;
    }
  }
}
