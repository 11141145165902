@import "~styles/theme/index";

.#{$wf__ns}pace-task-screen {
  .controls {
    &__btn {
      width: 56px;
      font-size: 23px;
      color: #a9b0ba;

      &:active,
      &:focus,
      &:hover {
        color: #a9b0ba;
      }

      > * {
        vertical-align: top;
      }
      > .anticon {
        margin-top: 1px;
      }
    }
  }
}
