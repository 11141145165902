@import "~styles/theme/index";

.#{$wf__ns}permitting-jurisdiction {
  &__modal {
    .ant-modal-content > .ant-modal-body {
      .ant-form-item {
        align-items: initial;

        &-label > label {
          height: 40px;
        }
      }
    }

    .ant-modal-footer {
      justify-content: flex-start;
      margin-left: 205px;
    }
  }
}
