@import "~styles/theme/index";

.#{$wf__ns}editable-page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__content {
    .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
      border-radius: 4px 4px 0 0;
      background-color: $blue5;
    }

    .ant-collapse.disabled > .ant-collapse-item > .ant-collapse-header {
      cursor: not-allowed;
    }
  }

  .#{$wf__ns}switch-box {
    .editable-switch {
      margin-right: 13px;

      &.ant-switch {
        &::after {
          background: $blue2;
        }

        .ant-switch-checked {
          background: rgba(24, 144, 255, 0.2);

          &::after {
            background: $primary;
          }
        }
      }
    }
  }
}
