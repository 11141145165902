@import "~styles/theme/index";

$borderRadius: 4px !important;

.#{$wf__ns}jurisdiction-configurable-panel {
  .ant-collapse-item {
    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    .ant-collapse-header {
      background-color: $blue5 !important;
      border-radius: initial !important;
    }

    .ant-collapse-content {
      padding: initial;
      overflow: initial;

      .ant-collapse-content-box {
        padding: 30px;
      }
    }

    &-active {
      box-shadow: 0 0 15px 0 rgba(#000, .5);
      border-radius: 4px !important;

      .ant-collapse-header {
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
      }
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .left {
      grid-column: 1 / 2;
    }

    .right {
      grid-column: 2 / 3;
    }

    .textarea {
      .ant-input {
        height: 95px;
      }
    }

    .ant-form-item {
      align-items: flex-start;
      height: auto;
    }
  }

  .ant-divider.ant-divider-horizontal {
    border-color: $blue12;
  }
}
