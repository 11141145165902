.engineering-browse__modal {
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 30px;
    }

    .ant-modal-footer {
      margin-left: 204px;
      padding-bottom: 30px;
    }
  }
}
