@import "~styles/theme/index";

.#{$wf__ns}jurisdiction-zoning {
  width: $full-res;
  color: $font-color;

  .page-wrap {
    overflow-y: auto;
    max-height: 67vh;

    &_with-actions {
      max-height: 60vh;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    .action-wrap {
      display: flex;
      justify-content: space-between;
      width: 332px;
    }

    .danger-btn {
      width: 180px;
    }
  }
}
