@import "~styles/theme/index";

.#{$wf__ns}site-audit-form {
  &__pdf-window {
    width: 100%;
    height: 1300px;
  }

  .attachments-header {
    @include tbx {
      margin-top: 2em;
    }
  }

  .tabs-wrap {
    padding-right: 13px;

    .pdf-wrap {
      height: 1335px;

      &__not-edit {
        margin-bottom: 3em;
      }

      &__header {
        display: flex;
        align-items: center;
        height: 54px;
        padding: 12px 40px 12px 16px;
        background: $blue5;
        color: $gray1;
        font-weight: 500;
        font-size: 17px;
        line-height: 28px;
        border-radius: 4px 4px 0 0;

        @include tbx {
          margin-top: 2em;
        }
      }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-content {
      height: max-content;
      margin-bottom: 0;
    }

    > .ant-row {
      margin-top: 24px;

      .ant-col-12 {
        @include tbx {
          min-width: 100%;
        }

        .ant-collapse-content {
          padding: 32px;
        }

        .power-plant {
          &__wrapper,
          &-type {
            width: 100%;
          }
        }
      }

      > .ant-col-12:first-child {
        max-width: 53%;
        flex: 0 0 53%;
        margin-right: 2%;
      }

      > .ant-col-12:last-child {
        max-width: 45%;
        justify-content: flex-end;
      }

      .ant-collapse > .ant-collapse-item {
        > .ant-collapse-header {
          display: flex;
          align-items: center;
          background: $blue5;
          height: 54px;
          font-weight: 500;
          font-size: 17px;
          line-height: 28px;
        }

        > .ant-collapse-content > div > form > .ant-row {
          > .ant-col {
            @include landscape {
              min-width: 50%;
            }

            @include tb {
              min-width: 100%;
            }
          }
        }
      }
    }
  }

  &__information {
    .ant-collapse-content {
      padding: 32px 0;
    }
  }

  .ant-col-24 {
    .ant-form-item-control {
      @include landscape {
        max-width: 250px;
      }

      @include tb {
        max-width: 400px;
      }
    }

    .ant-form-item-label {
      @include tbx {
        min-width: 225px;
      }
    }

    .power-plant {
      &__wrapper-active {
        display: flex;
        flex-wrap: nowrap;

        .prov-switch-box {
          display: flex;
          flex-wrap: nowrap;
          margin-top: 10px;
        }
      }

      &-type {
        max-width: 73%;
        display: flex;
        justify-content: flex-end;
      }
    }

    &:last-child {
      margin-top: 2em;

      .ant-collapse-content {
        padding: 32px;

        .prov-drag-and-drop-uploader {
          padding: 0;

          .ant-upload {
            height: 190px;
          }

          &__accepted-types {
            margin-top: 32px;
          }
        }
      }
    }
  }

  &__equipment {
    margin-top: 2em;

    > div {
      @include tbx {
        min-width: 100%;
      }

      .ant-table-thead .ant-table-cell,
      .ant-table-thead .ant-table-cell:hover {
        @include tbx {
          line-height: 14px;
          min-width: 100px;
        }
      }
    }
    .ant-col-12 {
      &:first-child {
        max-width: 49%;

        @include tbx {
          margin-bottom: 25px;
        }
      }

      .ant-col-24:first-child {
        margin-bottom: 2em;
      }

      &__action-button {
        margin-top: 20px;
      }
    }

    .controls {
      display: flex;
      justify-content: flex-end;

      .ant-btn {
        border: none;

        &.active,
        &:active,
        &:focus,
        &:hover {
          background: transparent;
        }
      }
    }
  }

  &__equipment__action-button {
    margin-top: 30px;
  }
}
