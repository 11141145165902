@import "~styles/theme/index";

.#{$wf__ns}import {
  width: $full-res;
  min-height: 330px;
  background: $blue4;
  margin-bottom: 2em;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 10px 30px;
    border-radius: 4px;
    background-color: $blue3;

    .title {
      font-size: 17px;
      color: $gray1;
    }

    .download-button {
      width: 240px;
    }
  }

  &__body {
    padding: 30px;
    height: 100%;
  }
}
