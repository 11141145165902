.map-container {
    .map {
        height: 65vh;

        .leaflet-tooltip {
            font-weight: bold;
            background-color: transparent;
            border: none;
            box-shadow: none;
        }

        .leaflet-popup{
            &-title {
            font-weight: bold;
            margin-bottom: 20px;
            };

            &-body {
                display: flex;
                flex-direction: column;
            }

            &-content {
            margin: 10px;
           };
        }
    }
}
