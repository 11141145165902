@import "~styles/theme/index";

.#{$wf__ns}select-with-button {
  width: 100%;
  min-width: 385px;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 4px;
  }

  &__select-options-container {
    .select-option {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .controls {
        &__btn {
          width: 20px;
          height: 20px;
          padding: 0;
          border: none;
          cursor: pointer;

          &:hover,
          &:active,
          &:focus {
            background: none;
          }

          .plus-circle-icon svg {
            fill: $primary;
            min-height: 20px;
            min-width: 20px;
          }
        }
      }
    }
  }
}
