@import "~styles/theme/index";

.switch {
  & > .prov-switch-box {
    display: flex;
    align-items: center;
  }

  &__active > .prov-switch-box {
    color: $primary;
  }
}
