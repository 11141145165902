@import "~styles/theme/index";

.#{$wf__ns}primary-button {
  display: inline-block;
  min-width: 150px;

  &__wrap.ant-btn {
    &[disabled] {
      background-color: $gray2;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    width: $full-res;
    height: 40px;
    border-radius: 5px;
  }

  &__icon {
    display: flex;
    margin-right: 6px;
    font-size: 36px;
  }
}
