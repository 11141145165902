@import "~styles/theme/index";

.#{$wf__ns}jurisdiction-summary-section {
  margin-bottom: 25px;

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .left {
      grid-column: 1 / 2;
    }

    .right {
      grid-column: 2 / 3;
    }

    .ant-form-item {
      align-items: flex-start;
      height: auto;
    }

    .left,
    .right {
      &.with-validation {
        .ant-form-item {
          &-explain {
            position: absolute;
            bottom: -23px;
          }
        }
      }
    }

    .text-area {
      .ant-input {
        height: 95px;
      }
    }
  }
}
