@import "~styles/theme/index";

.#{$wf__ns}accounting-browse {
  &__handler {
    &-bottom-row {
      margin-top: 15px;

      .ant-form {
        display: flex;

        &-item {
          padding: 0 15px 0 0;

          .ant-select {
            &-selector {
              width: 236px;
            }
          }
        }
      }
    }
  }

  &__modal {
    .ant-modal-content {
      .ant-modal-body {
        padding-left: 0;
        padding-right: 25px;

        .aligned-form-item {
          align-items: baseline;
        }
      }

      .ant-modal-footer {
        justify-content: flex-start;
        margin-right: 38px;
        margin-left: 178px;
      }
    }
  }

  .ant-radio-button-wrapper:first-child {
    border-color: $primary;
  }
}
