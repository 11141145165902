@import "~styles/theme/index";

.#{$wf__ns}site-information {
  width: $full-res;

  &__info {
    color: $gray2;

    .#{$wf__ns}switch-box {
      display: flex;
      align-items: center;
    }

    &.ant-col {
      max-width: 70px;
      margin-right: 60px;
    }

    &-text {
      margin-left: 11px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    .header-title {
      font-weight: 500;
      font-size: 17px;
      line-height: 28px;
      color: $white;
      margin-left: 20px;
    }
  }

  &__panel > .ant-collapse-content > .ant-collapse-content-box {
    padding: 30px 0;
  }

  &__btn-wrap {
    justify-content: space-between;
    margin-top: 24px;

    .prov-primary-button {
      &:first-child {
        margin-right: 32px;
      }
    }

    .site-delete {
      width: 180px;
    }
  }

  .tabs-wrap {
    &.tabs-wrap_with-actions {
      .ant-collapse .ant-collapse-item {
        .ant-collapse-content {
          height: calc(100vh - 535px);
        }
      }
    }

    .ant-collapse .ant-collapse-item {
      .ant-collapse-content {
        height: calc(100vh - 470px);
      }
    }
  }
}
