@import '~styles/theme/index';

.#{$wf__ns}site-upload {
  max-width: 500px;
  padding-left: 24px;
  color: $secondary;

  &__tooltip {
    margin-bottom: 5px;
  }

  &__rules {
    justify-content: end;
    text-align: end;

    &.bottom {
      margin-top: 10px;
    }
  }

  &__uploader {
    &.ant-upload.ant-upload-drag {
      background: transparent;
      min-height: 100px;

      &.filled .ant-upload-list,
      .empty-block {
        height: 30vh;
        overflow: auto;
      }

      .empty-block,
      .ant-upload-btn {
        color: $secondary;
      }

      .empty-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }

  .ant-upload-list {
    max-height: 150px;
    overflow: auto;

    &-item {
      &:hover &-info {
        background-color: $blue4;
      }

      &-info .anticon svg,
      &-name {
        color: $secondary !important;
      }
    }
  }
}

div.ant-tooltip-inner {
  background-color: $blue6;
  padding: 5px;
}
