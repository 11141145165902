@import '~styles/theme/index';

.#{$wf__ns}project-management {
  &__modal {
    .ant-modal-content {
      .ant-modal-body {
        padding-left: 0;
        padding-right: 0;

        .ant-form-item {
          align-items: baseline;

          &.aligned-form-item {
            .ant-form-item {
              margin: 0;
            }
          }
        }
      }

      .ant-modal-footer {
        justify-content: flex-start;
        margin-left: 210px;
        margin-right: 15px;
      }
    }
  }

  &__card.ant-card {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    padding: 23px;
    border-radius: 7px;

    button {
      &:hover,
      &:active {
        background: $blue7;
        color: $white;
      }
    }
  }

  &__popover.ant-popover {
    &:before {
      content: ' ';
      position: absolute;
      border: solid 10px transparent;
      border-bottom-color: $blue9;
      margin: -20px 0 0 calc(50% - 10px);
    }
  }

  &__filters {
    &-handler {
      justify-content: flex-end;

      .ant-row {
        width: 355px;
      }

      .prov-primary-button {
        min-width: 50px;
        margin-right: 15px;

        &__wrap.ant-btn {
          width: 50px;
        }

        &__icon {
          margin-right: 0;
        }
      }
    }

    .ant-divider.ant-divider-horizontal {
      border-color: $blue12;
    }
  }

  .#{$wf__ns}browse-page__filter {
    .switch-wrap {
      width: 100%;
      justify-content: start;
    }
  }
}
