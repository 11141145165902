@import '~styles/theme/index';

$slick-dots-height: 8vh;
$local-margin-size: 16px;

.#{$wf__ns}carousel {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: $site-pictures-carousel-height;

  &__delete-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: $local-margin-size;
  }

  .ant-carousel {
    width: 60vw;

    .slick-initialized .slick-slide {
      display: flex;
      justify-content: center;
    }

    .slick-slider {
      span.anticon.slick-arrow {
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $primary;
        top: 45%;

        &.slick-prev {
          left: -45px;
        }

        &.slick-next {
          right: -45px;
        }

        svg {
          height: 16px;
          width: 16px;
          color: black;
        }
      }

      .slick-slide {
        margin: 0 5px;

        div {
          width: 100%;

          img {
            height: calc(
              $site-pictures-carousel-height - $slick-dots-height -
                $local-margin-size * 2 - $main-button-height
            );
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }
      }

      .slick-dots {
        position: static;
        margin-top: $local-margin-size;

        li {
          width: 8vw;
          height: $slick-dots-height;
          text-indent: 0;
          background-color: $dark-blue;
          margin: 0 10px;

          &:not(.slick-active) {
            filter: grayscale(1);
          }
        }
      }

      .slick-track {
        display: flex;
        align-items: center;
      }
    }
  }
}
