@import '~styles/theme/index';

.#{$wf__ns}site-upload__edit-modal {
  .ant-modal-content {
    .ant-modal-body {
      display: flex;
      flex-wrap: nowrap;
      padding: 0;
    }

    .ant-modal-footer {
      display: none;
    }
  }

  &-img {
    max-width: 100%;
    max-height: 100%;

    &-wrapper {
      padding: 15px;
      max-width: 60vw;
      max-height: 80vh;

      &__container {
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }
  }

  &-handler {
    flex-wrap: wrap;

    &.ant-form {
      margin: 15px;
      width: 30vw;

      .ant-form-item {
        width: 100%;
      }

      .ant-slider {
        &-mark-text {
          color: $secondary;
        }

        &-rail,
        &:hover .ant-slider-rail {
          background-color: $dark-blue;
        }

        &-dot,
        &-handle {
          background-color: $dark-blue;
          border-color: $primary;
        }

        &-track,
        &:hover .ant-slider-track {
          background-color: $primary;
        }
      }

      .rotation-row,
      .handle-row,
      .save-btn-row {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
      }

      .save-btn-row {
        margin-top: 30px;
      }

      .rotation-row .ant-btn,
      .handle-row .prov-primary-button {
        margin: 0 5px;
      }
    }
  }
}
