@import "~styles/theme/index";

.#{$wf__ns}engineering-workflow {
  &__panel > .ant-collapse-content > .ant-collapse-content-box {
    padding: 30px 0;
  }

  .reference-panel {
    margin-bottom: 24px;
  }

  .rfds-panel {
    margin-bottom: 24px;
  }
}
