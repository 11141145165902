@import "~styles/theme/index";

.#{$wf__ns}rad-columns-btn {
  display: flex;

  &__modal {
    .ant-modal-footer {
      margin-right: 7px;
    }
  }

  .edit-icon {
    display: flex;
    height: $full-res;

    &.ant-btn {
      border: none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        background: transparent;
        border: none;
      }

      img {
        width: 18px;
      }
    }
  }
}
