@import "~styles/theme/index";

.#{$wf__ns}archive-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 30px;

      .ant-table-selection-col {
        width: 140px;
      }
    }

    .ant-modal-footer {
      flex-direction: row;
      padding: 0 30px 30px;
      justify-content: left;

      .primary-btn_default {
        display: none;
      }
    }
  }
}
