@import "~styles/theme/index";

.#{$wf__ns}financial-tab {
  &__panel {
    margin-bottom: 24px;

    .services-actions {
      display: flex;
      justify-content: flex-end;

      .add-btn {
        min-width: 120px;
      }
    }

    .prov-custom-table:last-of-type {
      margin: 24px 0;
    }

    .controls {
      justify-content: flex-end;
      align-items: center;

      .ant-btn {
        border: none;
        color: $gray2;

        span > svg {
          width: 24px;
          height: 24px;
        }

        &.active,
        &:active,
        &:focus,
        &:hover {
          background: transparent;
        }
      }
    }

    &-btn-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .export-btn {
        width: 170px;
      }

      .add-btn {
        display: flex;
        justify-content: flex-end;

        .ant-btn {
          width: 120px;
        }
      }
    }
  }

  .ant-table-tbody > tr > td {
    padding: 20px 16px;
  }

  .ant-collapse-content {
    padding: 30px 32px 0 32px;

    &:last-of-type {
      padding-bottom: 30px;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    background: $blue5;
    height: 54px;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
  }

  .prov-financial-handle__btns {
    margin-top: 24px;

    .action-wrap {
      display: flex;
      justify-content: space-between;
      width: 332px;
    }
  }

  .tabs-wrap > .ant-collapse > .ant-collapse-item .ant-collapse-content {
    height: 100%;
  }
}
