@import "~styles/theme/index";

.#{$wf__ns}add-or-edit-modal {
  &__title {
    display: flex;

    .hideable-text {
      margin-right: 5px;
    }
  }
 }
