@import "~styles/theme/index";

.#{$wf__ns}associated-projects {
    width: $full-res;
    padding: 40px 30px;
    background-color: $blue4;
    border-radius: 10px;

    &__point {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 11px;
        &_green {background-color: $turquoise;}
        &_gray {background-color: $gray2;}
        &_orange {background-color: $orange;}

    }

    &__status-cell {
        display: flex;
        align-items: center;
        &_green {color: $turquoise;}
        &_gray {color: $gray2;}
        &_orange {color: $orange;}
    }

    &__btn-container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;
    }

    .ant-table-column-sorters {
        padding: 16px 7px;
    }

    tbody.ant-table-tbody > tr > td {
        padding: 7px;
      }
}
