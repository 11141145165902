@import "~styles/theme/index";

.#{$wf__ns}contacts {
  padding: 35px;
  background-color: $blue4;
  border-radius: 10px;

  &__form {
    .contacts-btn {
      width: 120px;
    }

    .contacts-icon {
      margin-right: 10px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-top: 35px;

    > *:not(:last-of-type) {
      margin-right: 15px;
    }
  }

  &__modal {
    .ant-modal-content {
      .ant-modal-body {
        padding: 30px 0;

        .ant-form-item {
          align-items: center;

          &:last-of-type {
            align-items: flex-start;
          }
        }
      }

      .ant-modal-footer {
        padding-bottom: 30px;
        margin-left: 105px;

        .primary-btn {
          &_primary {
            width: 155px;
            margin-right: 15px;
          }
        }
      }
    }
  }

  .controls {
    display: flex;

    .ant-btn {
      border: none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        background: transparent;
      }
    }
  }
}
