@import "~styles/theme/index";

@import "~styles/theme/index";

.#{$wf__ns}maps-handler {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &__button.prov-primary-button {
        min-width: 60px;
    }

    &__bar {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        .title {
            font-size: 18px;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 10px;
            color: $gray2;
        }

          .ant-input,
          .ant-input-number,
          .ant-select > div.ant-select-selector {
            height: 40px;
            min-width: 200px;
            display: flex;
            align-items: center;
          }

          .clear-radius-btn {
              margin-left: 20px;
          }
    }
}
