@import "~styles/theme/index";

.#{$wf__ns}error-area {
  background: $blue4;
  border-radius: 4px;
  width: $full-res;
  min-height: 200px;

  .error-list {

    &__element {
      display: flex;
      flex-wrap: wrap;
      width: $full-res;
      font-size: 12px;
      color: $font-color;
      margin-bottom: 20px;

      &__row-number {
        flex: 5%;
      }

      &__column-name {
        flex: 10%;
      }

      &__description {
        flex: 70%;
      }

      .element-panel {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        padding: 5px 10px;
        background: $error-red-transp;
        border: 1px solid $danger;
        border-radius: 4px;
      }

    }
  }

  .no-errors-text {
    font-size: 13px;
    color: $secondary;
    text-align: center;
  }

  .ant-collapse {
    &-header {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 18px 30px;
      border-radius: 4px;
      background-color: $blue3;
      font-size: 17px;
      color: $gray1;
      font-weight: 500;
    }

    &-content {
      position: relative;
      min-height: 100px;
      padding: 40px 30px 30px;
    }
  }

  .ant-collapse-item.ant-collapse-no-arrow > div.ant-collapse-header {
    padding-left: 30px;
  }
}
