@import "~styles/theme/index";

.#{$wf__ns}table-legend {
  color: $gray1;

  .title {
    display: inline-block;
    margin-bottom: 10px;
  }

  .list {
    display: flex;
    font-size: 13px;

    &__item {
      .circle {
        margin-right: 15px;
      }

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }
  }
}
