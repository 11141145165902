@import "~styles/theme/index";

.#{$wf__ns}scoping-summary {
  .tabs-wrap {
    overflow-y: auto;
    max-height: 61vh;

    &.categories-table-open {
      max-height: 41vh;
    }

    &_with-actions {
      max-height: 57vh;

      &.categories-table-open {
        max-height: 35vh;
      }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-content {
      height: max-content;
      margin-bottom: 0;
    }
  }

  > .ant-row {
    > .ant-col-10 {
      flex: 0 0 41%;
      max-width: 41%;
      margin-right: 2%;
    }

    > .ant-col-13 {
      flex: 0 0 57%;
      max-width: 57%;
    }
  }

  &__information {
    .ant-collapse-content .ant-collapse-content-box {
      padding: $main-padding-size;
    }

    margin-bottom: 2em;

    .action-buttons {
      padding: $main-padding-size;

      > :not(:last-child) {
        margin-right: 2em;
      }
    }
  }
}
