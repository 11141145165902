@import "~styles/theme/index";

.site-access-notes-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 10px;

      .edit-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        padding: 0 10px 10px;
      }
    }

    .ant-modal-footer {
      .ant-btn-primary {
        display: none;
      }
    }
  }
}
