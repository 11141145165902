@import "~styles/theme/index";

.#{$wf__ns}column-settings {
  $self: '#{&}-popup';

  &-popup.ant-card {
    padding: 1em;
    width: 230px;
    max-height: 600px;
    overflow-y: auto;

    $self: #{$self}__list;

    #{$self} {
      $self: #{$self}__item;

      #{$self} {
        display: flex;

        > label {
          display: flex;
          width: 80%;
          margin-right: 5px;

          > div {
            flex-direction: column;
            display: flex;

            &:first-child {
              margin-right: 1em;
            }
          }

        }

        .anticon[disabled] {
          cursor: not-allowed;
          color: $gray3;
        }
      }
    }
  }
}
