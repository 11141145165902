@import '~styles/theme/index';

.prov-sof-preview {
  &__content {
    width: 100%;
    height: 85vh;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
  }

  .iframe {
    width: 100%;
    height: 100%;
  }

  &.ant-modal {
    top: 50px;
  }

  .ant-modal-content {
    .ant-modal-body,
    .ant-modal-footer {
      padding: 0;
    }
  }
}

.prov-sof-email-editor {
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px;
  }

  textarea {
    background-color: $gray3;
    color: var(--pwa-white-color);
    border: none;
    border-radius: 5px;
    padding: 15px;
  }

  label {
    color: var(--pwa-white-color);
  }
}
