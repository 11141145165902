@import "~styles/theme/index";

.#{$wf__ns}bell-notifications {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto !important;
  margin-right: 65px !important;

  &__bell-icon {
    width: 25px;
    height: 25px;
  }

  &__notification-box {
    &.ant-card {
      width: 400px;
      max-height: 500px;
      padding: 20px;
      overflow: auto;
      border-radius: 10px;
    }

    > .ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .clear-btn {
        margin-bottom: 10px;
      }

      .notification-card {
        width: 100%;

        &.ant-card {
          border-radius: 10px;
          box-shadow: 0 0 15px 0 rgba(#000, .5);

          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }

        > .ant-card-body {
          > .redirected, > .not-redirected {
            position: relative;
            display: flex;
            align-items: center;
            padding: 25px 15px;
            color: $secondary;

            .clear {
              position: absolute;
              top: 10px;
              right: 10px;

              .close-icon {
                opacity: .5;

                &:hover {
                  opacity: 1;
                  cursor: pointer;
                }
              }
            }

            .icon {
              width: 35px;
              margin-right: 25px;
            }

            .content {
              display: flex;
              flex-direction: column;
              width: 100%;

              > .title {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 16px;
                line-height: 1;

                &--info {
                  color: $blue1;
                }

                &--warning {
                  color: $yellow;
                }
              }

              > .body {
                font-size: 12px;
              }
            }

            .date {
              position: absolute;
              right: 10px;
              bottom: 5px;
              font-size: 10px;
            }
          }

          > .redirected {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__clear-all-modal {
    .ant-modal-content {
      .ant-modal-body {
        display: flex;
        justify-content: center;

        > .content {
          margin-bottom: 30px;
          font-size: 20px;
          line-height: 28px;
          color: $white;
        }
      }

      .ant-modal-footer {
        .ant-btn-primary {
          margin-left: 0;
        }

        .ant-btn.primary-btn_danger-outline {
          margin-left: 25px;
        }
      }
    }
  }
}
