@import "~styles/theme/index";

.#{$wf__ns}textarea-show-more {
  textarea.ant-input.model-input {
    box-sizing: border-box;
    overflow: hidden;
    resize: none;
  }

  &__view {
    display: flex;
    flex-direction: column;

    button {
      max-width: 200px;
      justify-self: center;
    }
  }
}
