@import "~styles/theme/index";

.#{$wf__ns}custom-table {
  &__settings-block {
    margin-left: auto;
    display: flex;
    align-items: center;

    &__open-in-new-tab {
      margin-right: 1em;
      display: flex;
      align-items: center;

      &__text {
        font-size: 12px;
        margin-right: .5em;
      }
    }
  }
  &__count {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: $secondary;

    .quantity {
      margin-left: 5px;
      color: $primary;
    }
  }

  &__addition-content {
    display: flex;
    align-items: flex-end;
    margin: 1em 0 1em 0;
  }

  &__grid {
    overflow-x: auto;

    .ant-table-container {
      font-size: 13px;

      @include tbx {
        font-size: 11px;
      }
    }

    .ant-table-thead {
      background-color: $blue5;

      .empty-header {
        .ant-table-column-sorters {
          >span {
            min-height: 10px;
          }
        }

        span.ant-table-column-sorter.ant-table-column-sorter-full {
          margin-left: 0;
        }
      }
    }

    .ant-table-thead .ant-table-cell,
    .ant-table-thead .ant-table-cell:hover {
      background-color: $blue5;
      color: $gray1;
      border: none;
      line-height: 22px;

      > div {
        width: 100%;
      }
    }

    .ant-table-body {
      max-height: 50vh;
      overflow-x: auto;
      overflow-y: auto !important;
      color: $gray2;
      cursor: pointer;

      td {
        border: none;
        background-color: inherit !important;
        vertical-align: middle;
        line-height: 13px;

        @include tbx {
          min-width: 120px;
          line-height: 14px;
        }

        .project-category-cell {
          line-height: 19px;
          display: flex;
          flex-direction: column;
        }
      }

      tr.hoverable:hover {
        background: $blue8;
      }

      tr:not(.hoverable) {
        cursor: default;
      }

      .odd-row {
        background-color: $blue4;
      }

      .even-row {
        background-color: $blue3;
      }
    }

    .ant-table-pagination-right {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .ant-pagination-item,
      .ant-pagination-item-link,
      .ant-select {
        border-color: $secondary;
        border-radius: 6px;
        background-color: transparent;
        color: $secondary;

        .ant-select-selector {
          border: none;
          background: none;
          color: $secondary;
        }

        .ant-pagination-item-ellipsis,
        a,
        svg {
          color: $secondary;
        }

        &-active {
          border: $primary;
          background-color: $primary;

          a {
            color: $white;
          }
        }
      }

      .ant-pagination-total-text {
        margin-right: auto;
      }
    }
  }

  .custom-row-title {
    &_primary {
      color: $primary;

      * {
        color: $primary;
      }
    }

    &_secondary {
      color: $gray2;

      * {
        color: $gray2;
      }
    }

    &_white {
      color: $white;

      * {
        color: $white;
      }
    }

    &_red {
      color: $danger;

      * {
        color: $danger;
      }
    }

    &_orange {
      color: $orange;

      * {
        color: $orange;
      }
    }
  }
}
