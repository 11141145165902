@import "~styles/theme/index";

.#{$wf__ns}admin-vendors-modal {
  .ant-modal-footer {
    margin-left: 144px;
    margin-right: 34px;

    .ant-btn {
      width: 150px;
      margin-right: 47px;
    }
  }
}
