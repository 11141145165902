@import "~styles/theme/index";

.risk-popup {
    &.ant-card {
        width: 314px;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        padding: 23px;
        border-radius: 7px;
    }

    &__card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 30px;
        border: 1px solid $yellow;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 15px;

        &.high {
            border: 1px solid $yellow;
            color: $yellow;
            background: $yellow-transp;
        }

        &.inactive {
            border: 1px solid $red1;
            color: $red1;
            background: $red-transp;
        }
    }

    &__info {
        .ant-form-item {
            padding: 0;
            align-items: baseline;

            &-control {
                color: $white;
                word-break: break-word;
            }
        }
    }
}
