@import "~styles/theme/index";

.#{$wf__ns}jurisdiction-permitting {
  width: $full-res;
  color: $font-color;

  .page-wrap {
    overflow-y: auto;
    max-height: 67vh;

    &_with-actions {
      max-height: 60vh;
    }
  }
}
