@import "~styles/theme/index";

.#{$wf__ns}item-with-operator {
  $self: &;

  &.ant-form-item {
    margin: 0;
  }

  .ant-row {
    flex-wrap: nowrap;

      .ant-row.ant-form-item {
        width: 100%;
        padding: 0;
      }
  }

  &__operator {
    max-width: 60px;

    &.ant-form-item {
      margin-left: 10px;
    }
  }

  #{$self}__checkbox.ant-row.ant-form-item  {
    margin-left: 10px;
    width: 20px;
  }
}
