@import "~styles/theme/index";

.#{$wf__ns}access-pace-task-modal {
  &__row {
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
  }

  .ant-modal-content {
    .ant-select-selector {
      min-height: 64px;
    }

    .ant-modal-footer {
      justify-content: flex-start;
      margin-right: 59px;
    }
  }
}
