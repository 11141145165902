@import '~styles/theme/index';

.#{$wf__ns}admin-pace-tasks-browse {
  .ant-collapse-content {
    padding: 1em;
  }

  &__submit {
    margin-top: 1em;
  }
}
