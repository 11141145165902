@import "~styles/theme/index";

.permit-type-table  {
  &__add-button {
    margin-top: 30px;
  }
  // override
  .ant-table-row {
    height: 72px;
  }
}
