@import "~styles/theme/index";

.#{$wf__ns}project-browse {
  &__items {
    display: flex;
    align-items: center;

    &_col {
      flex-direction: column;
      align-items: flex-start;
    }

    &_green {
      color: $turquoise;
    }

    &_gray {
      color: $font-color;
    }

    &_orange {
      color: $orange;
    }

    .circle {
      margin-right: 11px;
    }
  }

  .ant-table-body td {
    padding: 10px 16px;
    line-height: 19px;
  }

  &__modal {
    &.prov-browse-page__filter-modal .ant-modal-content {
      .ant-modal-body {
        padding-left: 0;

        .ant-form-item {
          &.aligned-form-item {
            &--evened {
              align-items: flex-start;

              .ant-form-item {
                margin: 0;
              }
            }

            .ant-form-item {
              margin: 0;
            }
          }
        }
      }

      .ant-modal-footer {
        margin-right: 0;
        margin-left: 145px;

        .ant-btn {
          max-width: 130px;

          &.primary-btn_primary {
            margin-right: 15px;
          }
        }
      }
    }
  }
}


